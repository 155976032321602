import Header from './ui/header';
import { Root, type Props as RootProps } from './ui/root';
import Content from './ui/content';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ChatHeader } from './ui/chat/header/chat-header';
import { ChatDashboardContent } from './ui/chat/content/gadget-content';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { DashboardType } from './types';
import { ManageDashboardContainer } from './ui/manage';

export * from './types';

export default function Dashboard({
  width = 0,
  isEditMode = false,
  setEditMode,
  props,
}: {
  width?: number;
  isEditMode?: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardType?: DashboardType;
  props: RootProps;
}) {
  const client = new QueryClient();

  return (
    <>
      {props.dashboardType === 'EMPLOYEE' ? (
        <Root {...props} dashboardType={props.dashboardType}>
          <Header />
          <Content />
        </Root>
      ) : props.dashboardType === 'MESSENGER' ? (
        <QueryClientProvider client={client}>
          <TooltipProvider>
            <Root {...props} dashboardType={props.dashboardType}>
              <ChatHeader />
              <ChatDashboardContent width={width} />
            </Root>
          </TooltipProvider>
        </QueryClientProvider>
      ) : (
        <Root {...props} dashboardType={props.dashboardType}>
          <ManageDashboardContainer
            isEditMode={isEditMode}
            setEditMode={setEditMode}
          />
        </Root>
      )}
    </>
  );
}
