import { ChevronDownIcon } from '@dop-ui/icons/react/dop/24';
import { clsx } from 'clsx';
import * as React from 'react';
import { useTranslation } from '../../../../../shared/lib/i18n/client';
import * as apis from '../../../apis';
import HeaderContainer from '../container';
import { DashboardProps, GadgetApiParams } from '../../../types';
import { useDashboardContext } from '../../../context';
import { Button } from '../../../../../shared/ui/button';
import { Icon } from '../../../../../shared/ui/button/primitives';

const layouts = [
  {
    boxCount: 3,
    layout: 1,
    oldLayout: 1,
  },
  {
    boxCount: 3,
    layout: 2,
    oldLayout: 2,
  },
  {
    boxCount: 3,
    layout: 3,
    oldLayout: 3,
  },
  {
    boxCount: 5,
    layout: 4,
    oldLayout: 1,
  },
];

interface LayoutProps {
  layout: number;
}

export interface Props {
  toggleGadgetSpecs: (bool: boolean) => void;
  isVisibleGadgetSpecs: boolean;
}

export default function ControlToolbar({
  toggleGadgetSpecs = () => undefined,
  isVisibleGadgetSpecs = false,
}: Props) {
  const { t } = useTranslation();
  const [isTitleEditMode, setIsTitleEditMode] = React.useState(false);
  const context = useDashboardContext();
  const { currentDashboard, gadgets, originGadgets, prevDashboard } =
    context.state;
  const {
    addDashboards,
    setCurrentDashboard,
    setOriginGadgets,
    setPrevDashboard,
    editDashboard,
    removeDashboard,
    getCompanyDashboard,
    setGadgets,
    setEditMode,
  } = context.actions;

  const [name, setName] = React.useState(currentDashboard?.name || '');
  const orgDashboardRef = React.useRef<DashboardProps | null>(currentDashboard);
  const orgGadgetsRef = React.useRef<GadgetApiParams[]>(gadgets);

  const isActivated = (layout: LayoutProps) => {
    return `${currentDashboard!.layout}` === `${layout.layout}`;
  };

  const clear = () => {
    setOriginGadgets([]);
    setPrevDashboard(null);
    orgDashboardRef.current = null;
    orgGadgetsRef.current = [];
  };
  const reset = () => {
    setGadgets(orgGadgetsRef.current);
    setCurrentDashboard(orgDashboardRef.current);
  };

  const handleLayoutClick = (layout: number) => {
    setCurrentDashboard({
      ...currentDashboard!,
      layout,
    });
  };

  const handleDeleteClick = async () => {
    if (!currentDashboard) return;

    const curDashboardId = currentDashboard.dashboardId;
    await apis.deleteDashboard(curDashboardId);
    removeDashboard(curDashboardId);
    setCurrentDashboard(getCompanyDashboard() || null);
    setEditMode(false);
  };

  const createDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.createDashboard(
      {
        name: currentDashboard.name,
        layout: currentDashboard.layout,
        gadgetList: gadgets,
      },
      'EMPLOYEE',
    );
    const createdDashboard = {
      ...currentDashboard,
      ...resp,
      // 임시 로직. Response 값에 activated가 false로 내려와서 임시로 추가함
      ...{ activated: true },
    };
    addDashboards(createdDashboard);
    setCurrentDashboard(createdDashboard);
    clear();
    setEditMode(false);
  };

  const updateDashboard = async () => {
    if (!currentDashboard) return;

    const resp = await apis.updateDashboard({
      dashboardId: currentDashboard.dashboardId,
      name: currentDashboard.name,
      layout: currentDashboard.layout,
      gadgetList: gadgets,
    });
    const updatedDashboard = { ...currentDashboard, ...resp };
    editDashboard(updatedDashboard);
    clear();
    setEditMode(false);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleTitleSaveClick = () => {
    if (!currentDashboard) return;
    const dashboard = { ...currentDashboard, name };
    editDashboard(dashboard);
    setCurrentDashboard(dashboard);
    setIsTitleEditMode(false);
  };

  const handleTitleCancelClick = () => {
    if (!currentDashboard) return;
    setName(currentDashboard.name);
    setIsTitleEditMode(false);
  };

  const handleSaveClick = async () => {
    if (!currentDashboard) return;
    try {
      if (currentDashboard.dashboardId === -1) {
        await createDashboard();
      } else {
        await updateDashboard();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleResetClick = () => {
    reset();
  };

  const handleCancelClick = () => {
    if (!currentDashboard) return;

    setGadgets(originGadgets);
    setCurrentDashboard(prevDashboard);
    setEditMode(false);
  };

  const curDashboardName = currentDashboard ? currentDashboard.name : '';

  return (
    <HeaderContainer>
      <div className="flex items-center w-full">
        <div className="flex items-center gap-x-2 shrink-0">
          {!isTitleEditMode && (
            <>
              <span className="text-[1rem]">{curDashboardName}</span>
              <Button size="md" variant="ghost" colorset="level1" shape="round">
                <Icon>
                  <span className="ic_side ic_list_reorder"></span>
                </Icon>
              </Button>
            </>
          )}
          {isTitleEditMode && (
            <span className="txt_edit">
              <input
                className="input edit wfix_large"
                type="text"
                name="title"
                value={name}
                onChange={handleTitleChange}
              />
              <span className="btn_wrap ui_edit_ing">
                <button
                  className="ic_side ic_done"
                  onClick={handleTitleSaveClick}
                ></button>
                <button
                  className="ic_side ic_cancel"
                  onClick={handleTitleCancelClick}
                ></button>
              </span>
            </span>
          )}
        </div>

        <ul className="option_layout grow flex justify-center items-center">
          {layouts.map((layout) => (
            <li
              key={`layout-${layout.boxCount}-${layout.oldLayout}`}
              className={clsx({ on: isActivated(layout) })}
              onClick={() => handleLayoutClick(layout.layout)}
              aria-hidden="true"
            >
              <span
                className={`ic_dashboard2 ic_layout${layout.boxCount}_${layout.oldLayout}`}
              ></span>
            </li>
          ))}
        </ul>
        <div className="flex items-center gap-x-1">
          <Button
            onClick={handleResetClick}
            size="md"
            variant="outline"
            colorset="level2"
            shape="rect"
          >
            {t('초기화')}
          </Button>
          <Button
            size="md"
            variant="outline"
            colorset="level2"
            shape="rect"
            onClick={() => void handleDeleteClick()}
          >
            {t('삭제')}
          </Button>

          <Button
            onClick={() => void handleCancelClick()}
            size="md"
            shape="rect"
            variant="solid"
            colorset="level1"
          >
            {t('취소')}
          </Button>
          <Button
            onClick={() => void handleSaveClick()}
            size="md"
            shape="rect"
            variant="solid"
            colorset="level1"
          >
            {t('완료')}
          </Button>
          <Button
            title={t('가젯 목록 보기')}
            onClick={() => toggleGadgetSpecs(!isVisibleGadgetSpecs)}
            size="md"
            shape="round"
            variant="ghost"
            colorset="level1"
          >
            <Icon>
              <ChevronDownIcon
                className={isVisibleGadgetSpecs ? 'rotate-180' : undefined}
              />
            </Icon>
          </Button>
        </div>
      </div>
    </HeaderContainer>
  );
}
